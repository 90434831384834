import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { ChartData } from "react-native-chart-kit/dist/HelperTypes";
import moment, { Moment, unitOfTime } from "moment";
import { getAuthToken, getUserDetails, handleTokenError } from "../../../components/src/NativeWebRouteWrapper/Utils";
import { getStorageData } from "../../../framework/src/Utilities";
import { ChangeEvent } from "react";

interface Reporting {
  overview: { unit: number; revenue: number };
  sales: { [label: string]: number };
  salesCumulative: { [label: string]: number };
  revenueBreakdown: { [label: string]: number };
  salesComparison: { [label: string]: number };
  currency: string;
}
interface RevenueBreakdownUnit {
  name: string;
  value: number;
  color: string;
  legendFontColor: string;
  legendFontSize: number;
}

export interface ApiCallInterface {
  contentType?: string;
  method?: string;
  endPoint?: string;
  body?: object;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export type Order = {
  orderNumber: string;
  itemsQty: number;
  totalPrice: string;
  customer: string;
  shipByDate: string;
  status: Status;
};

export interface Status {
  delivered?: boolean;
  shipped?: boolean;
  delayed?: boolean;
  paid?: boolean;
  paidViaWarpPay?: boolean;
  atWarpSpeed?:boolean
}

interface Customer {
  id: number;
  name: string;
  address: string;
  created_at: string;
  updated_at: string;
  admin_user_id: number;
  district_id: number | null;
}

interface OrderReceipt {
  url: string;
}

interface Emoji {
  url: string;
}

interface OrderAttributes {
  order_status: string;
  quantity: number;
  status: string;
  cost: number | null;
  start_date: string | null;
  end_date: string | null;
  classbox_id: number;
  account_id: number | null;
  checkout_month: number;
  shipped_from: string;
  shipped_to: string;
  tracking_url: string;
  earliest_expected_delivery_date: string | null;
  latest_expected_delivery_date: string | null;
  payment_status: string;
  customer: Customer;
  total_price: number;
  classbox_name: string;
  order_receipt: OrderReceipt;
  classbox_hero_emoji: Emoji;
  classbox_emoji: Emoji;
  packing_list: PackingList;
  ship_by_date:string|null;
}
interface PackingList {
  data: PackingPart[];
}

interface PackingPart {
  id: string;
  type: string;
  attributes: PackingPartAttributes;
}
interface PackingPartAttributes {
  name: string;
  checkout_part: boolean;
  purchase_part: boolean;
  supplier: string | null;
  quantity: number;
  unit: string;
  credits_cost: number;
}
interface OrderDetails {
  id: string;
  type: string;
  attributes: OrderAttributes;
}

interface OrderDetail {
  customerName: string;
  shipmentAddress: string;
  type: string;
  shipByDate: string;
  status: string;
  earliestDelivery: string;
  latestDelivery: string;
  trackingLink: string;
}

interface UpdateShippingDetailsRequest {
  data: {
    attributes: {
      tracking_url: string; 
      earliest_expected_delivery_date: string | null; 
      latest_expected_delivery_date: string | null; 
      status: string; 
    };
  };
}
interface S {
  // Customizable Area Start
  token: string;
  loading: boolean;
  selectedPeriod: { pkey: string; value: string };
  selectedDate: { from: Moment; through: Moment };
  selectedFrame: string;
  totalSaleUnit: number;
  totalSaleRevenue: number;
  revenueBreakdown: RevenueBreakdownUnit[];
  mobileSalesData: ChartData;
  mobileSalesCumulativeData: { labels: string[]; data: number[] };
  webSalesData: (string | number)[][];
  webSalesCumulativeData: (string | number)[][];
  webRevenueBreakdown: (string | number)[][];
  currency: string;
  pendingOrders : number;
  pendingOrderTotal : string;
  nextShipDate : string;
  orders : Order[];
  orderData:OrderDetails[];
  pending_orders_count: number;
  pending_orders_payment: number;
  next_ship_date: string;
  isFilterOpen: boolean;
  anchorEl: HTMLElement | null;
  activeFilterType:string
  selectedFilters: {
    notshipped: boolean;
    shipped: boolean;
    delivered: boolean;
    delayed:boolean;
    notpaid:boolean;
    paid:boolean;
    accelerated:boolean;
  };
  isDrawerOpen:boolean,
  order:OrderDetail,
  isPcakageing:boolean,
  index:number,
  orderNumber:string,
  activeFilterLabels: string[],
  isFilterLoading: boolean,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SalesReportingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  filterDataApiCallId: string = "";
  salesReportApiCallId: string = "";
  getOrderDataId:string="";
  getAnalyticDataId:string="";
  updateShippingDetailsApiId:string="";
  public filterRef: HTMLDivElement | null = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.setFilterRef = this.setFilterRef.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    const chartData = this.prepareChartData(configJSON.sampleReporting);

    this.state = {
      token: "",
      // Customizable Area Start
      loading: false,
      selectedPeriod: { pkey: '', value: '' },
      selectedDate: { from: moment(), through: moment() },
      selectedFrame: "week",
      totalSaleUnit: chartData.totalSaleUnit,
      totalSaleRevenue: chartData.totalSaleRevenue,
      revenueBreakdown: chartData.revenueBreakdown,
      mobileSalesData: chartData.mobileSalesData,
      mobileSalesCumulativeData: chartData.mobileSalesCumulativeData,
      webSalesData: chartData.webSalesData,
      webSalesCumulativeData: chartData.webSalesCumulativeData,
      webRevenueBreakdown: chartData.webRevenueBreakdown,
      currency: chartData.currency,
      pendingOrders: 0,
      pendingOrderTotal: '\$0',
      nextShipDate: '',
      orders: [],
      orderData:[], 
      pending_orders_count: 0,
      pending_orders_payment: 0,
      next_ship_date: "",
      isFilterOpen: false,
      anchorEl: null,
      activeFilterType:"",
      selectedFilters: {
        notshipped: false,
        shipped: false,   
        delivered: false,
        delayed:false,
        notpaid:false,
        paid:false,
        accelerated:false,
      },
      activeFilterLabels: [],
      isFilterLoading: false,
      isDrawerOpen:false,
      order: {
        customerName: "Betabox, Inc.",
        shipmentAddress: "715 Barbour Dr. Raleigh, NC 27603",
        type: "Purchase",
        shipByDate: "2023-11-25",
        status: "Shipped",
        earliestDelivery: "2023-11-21",
        latestDelivery: "2023-11-24",
        trackingLink: "https://www.web.com/track...",
      },
      isPcakageing:true,
      index: 0,
      orderNumber:"",
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token });
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const getSalesReportResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      runEngine.debugLog("API Message Recived", getSalesReportResponse);

      if (getSalesReportResponse && getSalesReportResponse.overview) {
        const chartData = this.prepareChartData(getSalesReportResponse);
        this.setState({ ...chartData, loading: false });
      } else {
        this.showAlert("Alert", "API Error", "");
      }
    }
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      handleTokenError(responseJson);
      if (responseJson) {
        this.setState({
          loading: false,
          isFilterLoading: false,
        });
      }
      if (responseJson && !responseJson.errors) 
        {
          if (apiRequestCallId === this.getOrderDataId) { 
           this.setState({
            orderData:responseJson.data
           })
          }
          if (apiRequestCallId === this.getAnalyticDataId) {
            this.setState({
              pending_orders_count:responseJson.data.details.pending_orders_count,
              pending_orders_payment:responseJson.data.details.pending_orders_payment,
              next_ship_date: responseJson.data.details.next_ship_date,
             })
          }
          if (apiRequestCallId === this.filterDataApiCallId) {
            if (responseJson.data && responseJson.data.length > 0) {
              this.setState({
                orderData: responseJson.data,
                isFilterLoading: false,
              });
            }
          }
        }
        else{
          if (apiRequestCallId === this.filterDataApiCallId) {
            if (responseJson.errors[0].order === "orders not found") {
              this.setState({
                orderData: [],
                isFilterLoading: false,
              });
            }
          }
        }
    }
    // Customizable Area End
  }

  // Customizable Area End

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    document.addEventListener('mousedown', this.handleClickOutside);
    this.getSalesReport();
    this.getAnalyticData();
    this.getOrderData();
    // Customizable Area End
  }
  setFilterRef = (ref: HTMLDivElement | null) => {
    this.filterRef = ref;
  };
  async componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }
  apiCall = async (apiData: ApiCallInterface) => {
    const token = await getStorageData("authToken");
    const { contentType, method, endPoint } = apiData;
    const header = {
      "Content-Type": contentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };
  handleClickOutside = (event: MouseEvent): void => {
    if (
      this.filterRef && 
      event.target instanceof Node && 
      !this.filterRef.contains(event.target) && 
      this.state.isFilterOpen
    ) {
      this.setState({ isFilterOpen: false });
    }
  }
  filterDataApi = async (filters: { [key: string]: boolean }) => {
    const queryParams = Object.entries(filters)
    .filter(([_, value]) => value)
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`);

    const finalQueryParams = queryParams.length > 0 
    ? [...queryParams, 'page=1', 'per_page=10'].join('&')
    : '';

    console.log(finalQueryParams,"queryParams");
    
    const endPoint = `${configJSON.filterEndPonit}?${finalQueryParams}`;
  
    this.filterDataApiCallId = await this.apiCall({
      contentType: configJSON.filterDataContentType,
      method: configJSON.filterDataMethod,
      endPoint: endPoint
    });
    this.setState({ isFilterLoading: true });
  };
  setSelectedPeriod = (period: { pkey: string; value: string }) => {
    let momentKey: unitOfTime.StartOf;
    switch (period.pkey) {
      case "day":
        momentKey = "week";
        break;
      case "week":
        momentKey = "month";
        break;
      case "month":
        momentKey = "year";
        break;
      default:
        momentKey = "day";
    }
    const start = moment().startOf(momentKey);
    const ends = moment().endOf(momentKey);
    this.setState({
      selectedFrame: period.pkey,
      selectedDate: { from: start, through: ends },
    });
    this.getSalesReport(
      this.state.token,
      period.pkey,
      start.valueOf(),
      ends.valueOf()
    );
  };

  setSelectedDate = (direction: string = "left") => {
    let momentKey: unitOfTime.StartOf;
    switch (this.state.selectedFrame) {
      case "day":
        momentKey = "week";
        break;
      case "week":
        momentKey = "month";
        break;
      case "month":
        momentKey = "year";
        break;
      default:
        momentKey = "day";
    }

    const start =
      direction === "left"
        ? this.state.selectedDate.from.subtract(1, momentKey)
        : this.state.selectedDate.from.add(1, momentKey);
    const ends =
      direction === "left"
        ? this.state.selectedDate.through.subtract(1, momentKey)
        : this.state.selectedDate.through.add(1, momentKey);

    this.setState({ selectedDate: { from: start, through: ends } });
    this.getSalesReport(
      this.state.token,
      this.state.selectedFrame,
      start.valueOf(),
      ends.valueOf()
    );
  };

  getSalesReport = (
    token: string = "",
    frame: string = this.state.selectedFrame,
    start: number = this.state.selectedDate.from.valueOf(),
    ends: number = this.state.selectedDate.through.valueOf()
  ) => {
    const header = {
      "Content-Type": configJSON.getSalesReportApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    let params = new URLSearchParams();
    params.append("frame", frame);
    params.append("start", start.toString());
    params.append("end", ends.toString());

    this.salesReportApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getSalesReportApiEndPoint}?${params.toString()}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    this.setState({ loading: true });
  };

  decideLabels = () => {
    const frameKey = this.state?.selectedFrame || "week";
    let labels: string[] = [];
    if (frameKey !== "week") {
      labels = configJSON.chartLabels[this.state?.selectedFrame];
    }

    if (labels.length === 0) {
      const from = this.state?.selectedDate.from || moment().startOf("month");
      const ends = this.state?.selectedDate.through || moment().endOf("month");
      let tempDate = moment(this.state?.selectedDate.from).startOf("week");
      while (tempDate.isBefore(ends) || tempDate.isSame(ends)) {
        if (tempDate.isAfter(from) || tempDate.isSame(from)) {
          labels.push(tempDate.format("DD.MM.YYYY"));
        }
        tempDate = moment(tempDate.add(1, "w"));
      }
    }
    return labels;
  };
  getOrderData =async () => {
    const token = await getStorageData("authToken");
    const header = {
      token: token,
    };
    const requestCompanyData = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getOrderDataId = requestCompanyData.messageId;
    requestCompanyData.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.orderDataEndPoint
    );
    requestCompanyData.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestCompanyData.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    this.setState({
      loading: true,
    });

    runEngine.sendMessage(requestCompanyData.id, requestCompanyData);
    return true;
  };

  getAnalyticData = async () => {
    const token =await getStorageData("authToken");
    const header = {
      token: token,
    };
    
    const requestCompanyData = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAnalyticDataId = requestCompanyData.messageId;
    requestCompanyData.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.orderAnalyticsEndPoint
    );
    requestCompanyData.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestCompanyData.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    this.setState({
      loading: true,
    });

    runEngine.sendMessage(requestCompanyData.id, requestCompanyData);
    return true;
  };
  updateShippingDetails(data: UpdateShippingDetailsRequest,id:string) {
    let token = getAuthToken();
    const header = {
      token: token,
      "Content-Type": "application/json",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateShippingDetailsApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "classbox/order_details/" + id + "/update_order"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPatchMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    this.setState({
      loading: true,
    });

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  prepareChartData = (reporting: Reporting) => {
    let labels: string[] = this.decideLabels();

    let hourLabels: string[] = [];

    const mobileSalesData: number[] = [];
    const mobileSalesCumulativeData: number[] = [];
    const webSalesData: (string | number)[][] = [
      configJSON.webSalesChartDataLabels,
    ];
    const webSalesCumulativeData: (string | number)[][] = [
      configJSON.webSalesChartDataLabels,
    ];

    let sums = 0;
    let hourGroupSum = 0;
    labels.forEach((label: string, l_index: number) => {
      sums += reporting.sales[label] || 0;
      if (this.state?.selectedFrame === "hour" && l_index % 2 === 0) {
        hourLabels.push(label);
        hourGroupSum += reporting.sales[label] || 0;
        mobileSalesData.push(hourGroupSum);
        mobileSalesCumulativeData.push(sums);
        hourGroupSum = 0;
      } else if (this.state?.selectedFrame === "hour" && l_index % 2 !== 0) {
        hourGroupSum += reporting.sales[label] || 0;
      } else {
        mobileSalesData.push(reporting.sales[label] || 0);
        mobileSalesCumulativeData.push(sums);
      }

      webSalesData.push([label, reporting.sales[label] || 0]);
      webSalesCumulativeData.push([label, sums]);
    });

    let _revenueBreakdown: RevenueBreakdownUnit[] = [];
    Object.keys(reporting.revenueBreakdown).forEach((revenue, r_index) => {
      _revenueBreakdown.push({
        name: revenue,
        value: reporting.revenueBreakdown[revenue],
        color: configJSON.chartColors[r_index % configJSON.chartColors.length],
        legendFontColor: "#7F7F7F",
        legendFontSize: 15,
      });
    });

    return {
      currency: reporting.currency,
      totalSaleUnit: reporting.overview.unit,
      totalSaleRevenue: reporting.overview.revenue,
      mobileSalesData: {
        labels: hourLabels.length === 0 ? labels : hourLabels,
        datasets: [
          {
            data: mobileSalesData,
          },
        ],
      },
      mobileSalesCumulativeData: {
        labels: hourLabels.length === 0 ? labels : hourLabels,
        data: mobileSalesCumulativeData,
      },
      revenueBreakdown: _revenueBreakdown,
      webSalesData,
      webSalesCumulativeData,
      webRevenueBreakdown: [
        configJSON.webRevenueBreakdownLabels,
        ...Object.keys(reporting.revenueBreakdown).map((report) => [
          report,
          reporting.revenueBreakdown[report],
        ]),
      ],
    };
  };
  handleRowClick = (index:number,orderNumber:string) => {
    this.setState({isDrawerOpen: true ,index:index,orderNumber:orderNumber});
    
  };
  handleShipmentDetails = () => {
    this.setState((prevState)=>({
     ...prevState,
     isPcakageing:!prevState.isPcakageing,
    }))
  }

  handleDrawerClose = () => {
    this.setState({isDrawerOpen: false});
  };
  handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      order: {
        ...prevState.order,
        [name]: value
      }
    }));
  };

  handleSubmit = (values:OrderAttributes) => {
   
    const orderId = this.state.orderData[this.state.index].id;
   
    const request={
      data:{
        attributes:{
          tracking_url:values.tracking_url,
          earliest_expected_delivery_date:values.earliest_expected_delivery_date,
          latest_expected_delivery_date:values.latest_expected_delivery_date,
          status:values.status
        }
      }
    }
    this.updateShippingDetails(request,orderId)
  };
  // Customizable Area End
}
