import React from "react";

// Customizable Area Start
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  ScrollView,
  Image,
} from "react-native";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
  Drawer,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import "../assets/vendorOrders.scss";
type FilterKey = 'notshipped' | 'shipped' | 'delivered' | 'delayed' | 'notpaid' | 'paid' | 'accelerated';
import { funnelIcon,titleIcon, deliveredPaidButton, deliveredWarpPayButton, deliveredAtWarpSpeedButton, shippedNotPaidButton, notShippedNotPaidButton, CloseIcon } from "./assets";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import {
  Typography
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";
import '../assets/VendorShipmentDetails.scss';
import { PackingListIcon, ShipmentDetailsIcon } from "./assets";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

// Merge Engine - import assets - Start
// Merge Engine - import assets - End

// Merge Engine - Artboard Dimension  - Start
let artBoardHeightOrg = 667;
let artBoardWidthOrg = 375;
// Merge Engine - Artboard Dimension  - End
const validationSchema = Yup.object({
  status: Yup.string().required('Order Status is required'),
  earliest_expected_delivery_date: Yup.date().required('Earliest Expected Delivery Date is required'),
  latest_expected_delivery_date: Yup.date().required('Latest Expected Delivery Date is required'),
  tracking_url: Yup.string().url('Must be a valid URL'),
});
// Customizable Area End

import SalesReportingController, {
  Order,
  Props,
  Status,
  configJSON,
} from "./SalesReportingController";
import GenericCard from "../../../components/src/GenericCard";
import { formatDateToMonth, getPaymentChip, getStatusChip } from "../../../components/src/utility";
import { getCommaSeperator } from "../../../components/src/NativeWebRouteWrapper/Utils";
import { formatDate } from "../../../components/src/comman";
export default class SalesReporting extends SalesReportingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.state = {
      ...this.state,
      isFilterOpen: false,
      activeFilterType: 'order',
      selectedFilters: {
        notshipped: false,
        shipped: false,
        delivered: false,
        delayed: false,
        notpaid: false,
        paid: false,
        accelerated: false,
      },
      activeFilterLabels: [],
    };
    this.handleClickOutside = this.handleClickOutside.bind(this);
    // Customizable Area End
  }

  // Customizable Area Start
  handleFilterClick = () => {
    this.setState(prevState => ({
      isFilterOpen: !prevState.isFilterOpen,
      activeFilterType: 'order',
    }));
  }
  handleFilterTypeClick = (type: 'order' | 'payment') => {
    this.setState({ activeFilterType: type });
  }
  handleFilterChange = (filterName: string) => {
    if (filterName in this.state.selectedFilters) {
      this.setState((prevState) => {
        const newFilters = {
          ...prevState.selectedFilters,
          [filterName]: !prevState.selectedFilters[filterName as FilterKey],
        };
        let newActiveFilterLabels = prevState.activeFilterLabels.filter(
          label => label.split(': ')[1] !== filterName
        );
        if (newFilters[filterName as FilterKey]) {
          newActiveFilterLabels.push(`${this.state.activeFilterType}: ${filterName}`);
        }
        this.setState({ isFilterLoading: true }, () => {
          this.filterDataApi(newFilters);
        });
        return { 
          selectedFilters: newFilters,
          activeFilterLabels: newActiveFilterLabels
        };
      });
    } 
  };
  removeFilter = (labelToRemove: string) => {
    const [filterType, filterName] = labelToRemove.split(': ');
    this.setState(prevState => {
      // Only update the filter if it exists in the current state
      const newFilters = filterName in prevState.selectedFilters
        ? {
            ...prevState.selectedFilters,
            [filterName]: false
          }
        : prevState.selectedFilters;
  
      const newActiveFilterLabels = prevState.activeFilterLabels.filter(
        label => label !== labelToRemove
      );
  
      // Only call filterDataApi if the filters actually changed
      if (newFilters !== prevState.selectedFilters) {
        this.filterDataApi(newFilters);
      }
  
      return {
        selectedFilters: newFilters,
        activeFilterLabels: newActiveFilterLabels
      };
    });
  };
  
  private _renderAnalytics = () => {
    return (
      <View style={styles.analyticsContainer}>
        <View style={styles.analyticsBox}>
          <Text style={styles.analyticsTitle}>Pending Orders</Text>
          <Text style={styles.analyticsValue}>{this.state.pending_orders_count}</Text>
          <Text style={styles.analyticsLink}>Show pending orders</Text>
        </View>
        <View style={styles.analyticsBox}>
          <Text style={styles.analyticsTitle}>Pending Order Total</Text>
          <Text style={styles.analyticsValue}>${getCommaSeperator(this.state.pending_orders_payment)}</Text>
          <Text style={styles.analyticsLink}>Show payouts</Text>
        </View>
        <View style={styles.analyticsBox}>
          <Text style={styles.analyticsTitle}>Next Ship Date</Text>
          <Text style={styles.analyticsValue}>{formatDate(this.state.next_ship_date)}</Text>
          <Text style={styles.analyticsLink}>Show order</Text>
        </View>
      </View>
    );
  };
  public get renderAnalytics() {
    return this._renderAnalytics;
  }
  public set renderAnalytics(value) {
    this._renderAnalytics = value;
  }
  renderFilterBlock = () => {
    const { isFilterOpen, activeFilterType, selectedFilters  } = this.state;
    if (!isFilterOpen) return null;

    return (
      <div className="filter_block" ref={this.setFilterRef}>
        <div className="filter_title">
          <div className="orders" data-testID="orderbtn" onClick={() => this.handleFilterTypeClick('order')}
            style={{
              background: activeFilterType === 'order' ? 'white' : '',
            }}
            >
            <span>
            <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.5399 3.25015C1.97265 2.61616 1.68902 2.29917 1.67833 2.02976C1.66903 1.79573 1.7696 1.57079 1.95021 1.42167C2.15812 1.25 2.58348 1.25 3.4342 1.25H14.5664C15.4171 1.25 15.8425 1.25 16.0504 1.42167C16.231 1.57079 16.3316 1.79573 16.3223 2.02976C16.3116 2.29917 16.0279 2.61616 15.4607 3.25015L11.181 8.0333C11.0679 8.15968 11.0114 8.2229 10.9711 8.29482C10.9353 8.35857 10.9091 8.42727 10.8932 8.4986C10.8753 8.57907 10.8753 8.6639 10.8753 8.83348V12.8438C10.8753 12.9904 10.8753 13.0638 10.8516 13.1272C10.8307 13.1832 10.7967 13.2334 10.7525 13.2736C10.7024 13.3191 10.6343 13.3464 10.4982 13.4009L7.94816 14.4209C7.67246 14.5311 7.53461 14.5863 7.424 14.5632C7.32724 14.5431 7.24234 14.4857 7.18774 14.4033C7.1253 14.3092 7.1253 14.1606 7.1253 13.8638V8.83348C7.1253 8.6639 7.1253 8.57907 7.10739 8.4986C7.0915 8.42727 7.06527 8.35857 7.02952 8.29482C6.9892 8.2229 6.93266 8.15968 6.81958 8.0333L2.5399 3.25015Z" stroke="#0099F3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            </span>
            Order Status
          </div>
          <div className="payments" data-testID="paybtn" onClick={() => this.handleFilterTypeClick('payment')}
             style={{
              background: activeFilterType === 'payment' ? 'white' : '',
            }}
          >
            <span>
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.57575 3C8.5227 2.07211 9.81953 1.5 11.25 1.5C14.1495 1.5 16.5 3.85051 16.5 6.75C16.5 8.18048 15.9279 9.47738 14.9999 10.4243M12 11.25C12 14.1495 9.6495 16.5 6.75 16.5C3.85051 16.5 1.5 14.1495 1.5 11.25C1.5 8.3505 3.85051 6 6.75 6C9.6495 6 12 8.3505 12 11.25Z" stroke="#667085" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            </span>
            Payment Status
          </div>
        </div>
        {activeFilterType === 'order' && (
          <div className="filter_values">
            <div className={`order_values ${selectedFilters.notshipped ? 'blueBack' : ''}`}>
              <input
                type="checkbox"
                checked={selectedFilters.notshipped}
                onChange={() => this.handleFilterChange('notshipped')}
              />
              <span>Not Shipped</span>
              { console.log("selectedFilters",this.state.selectedFilters)}
            </div>
            <div className={`order_values ${selectedFilters.shipped ? 'blueBack' : ''}`}>
              <input
                type="checkbox"
                checked={selectedFilters.shipped}
                onChange={() => this.handleFilterChange('shipped')}
              />
              <span>Shipped</span>
            </div>
            <div className={`order_values ${selectedFilters.delayed ? 'blueBack' : ''}`}>
              <input
                type="checkbox"
                checked={selectedFilters.delayed}
                onChange={() => this.handleFilterChange('delayed')}
              />
              <span>Delayed</span>
            </div>
            <div className={`order_values ${selectedFilters.delivered ? 'blueBack' : ''}`}>
              <input
                type="checkbox"
                checked={selectedFilters.delivered}
                onChange={() => this.handleFilterChange('delivered')}
              />
              <span>Delivered</span>
            </div>
          </div>
        )}
        {activeFilterType === 'payment' && (
          <div className="filter_values">
            <div className={`payments_value ${selectedFilters.notpaid ? 'blueBack' : ''}`}>
              <input
                datatest-Id="paidbtn1"
                type="checkbox"
                checked={selectedFilters.notpaid}
                onChange={() => this.handleFilterChange('notpaid')}
              />
              <span>Not Paid</span>
            </div>
            <div className={`payments_value ${selectedFilters.paid ? 'blueBack' : ''}`}>
              <input
               datatest-Id="paidbtn2"
                type="checkbox"
                checked={selectedFilters.paid}
                onChange={() => this.handleFilterChange('paid')}
              />
              <span>Paid</span>
            </div>
            <div className={`payments_value ${selectedFilters.accelerated ? 'blueBack' : ''}`}>
              <input
               datatest-Id="paidbtn3"
                type="checkbox"
                checked={selectedFilters.accelerated}
                onChange={() => this.handleFilterChange('accelerated')}
              />
              <span>Accelerated</span>
            </div>
          </div>
        )}
      </div>
    );
  }
  renderOrdersTable = () => {
    const {isFilterLoading,orderData,activeFilterLabels  } = this.state;
    const capitalizeFirstLetter = (str: string): string => {
      return str.charAt(0).toUpperCase() + str.slice(1);
    };
   
    return (
      <TableContainer component={Paper}>
        <div
          style={{
            padding: "20px",
            paddingRight:"30px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div className="orderDiv">
          <div style={{
            fontFamily: "Gotham",
            fontWeight: 500,
            fontSize: 24,
            lineHeight: "32px",
            color: "#344054"
          }}>
            Orders
          </div>
          <div className="orderLength">{this.state.orderData.length}</div>
          </div>
          <div className="filter">
          <Button
            variant="outlined"
            startIcon={<img src={funnelIcon} alt="Funnel Icon" style={{ width: '20px', height: '18px' }} />}
            endIcon={<KeyboardArrowDownIcon style={{width: '30px', height: '24px',color:'#667085'}}/>}
            style={{ 
            borderRadius: '20px', 
            backgroundColor:'#F9FAFB', 
            padding: '4px 16px',
            display:'flex',
            justifyContent:'space-between',
            border:'none',
          }}
          data-testId='Filter_btn'
          onClick={this.handleFilterClick}
          >
            <div  className="filter">Filter
            </div>
          </Button>
          {this.renderFilterBlock()}
          </div>
        </div>
        {activeFilterLabels.length > 0 && (
          <div className="filterstatus">
            {activeFilterLabels.map((label: string, index: number) => {
              const [filterType, filterValue] = label.split(': ');
              let displayValue = capitalizeFirstLetter(filterValue);
              if (filterValue.toLowerCase() === 'notshipped') {
                displayValue = 'Not Shipped';
              } else if (filterValue.toLowerCase() === 'notpaid') {
                displayValue = 'Not Paid';
              }
              
              const capitalizedFilterKey = capitalizeFirstLetter(filterType);
              
              return (
                <div key={index} className="filter-label-container">
                  <span className="filter-label">
                    {`${capitalizedFilterKey}: ${displayValue}`}
                    <button datatest-ID="removebtn" onClick={() => this.removeFilter(label)} className="remove-filter">×</button>
                  </span>
                </div>
              );
            })}
          </div>
        )}
        <Table className="orders-table"> 
          <TableHead>
            <TableRow className="table-row-headings" >
              <TableCell className="order-number" >Order Number</TableCell>
              <TableCell className="headings">Items QTY</TableCell>
              <TableCell className="headings">Total Price</TableCell>
              <TableCell className="headings">Customer</TableCell>
              <TableCell className="headings">Ship By Date</TableCell>
              <TableCell className="headings">Statuses</TableCell>
              <TableCell className="headings"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody data-test-id="tablebody">
          {isFilterLoading ? (
            <TableRow>
              <TableCell colSpan={7} align="center">
                <div className="filter-loader">
                  <CircularProgress size={24} />
                </div>
              </TableCell>
            </TableRow>
          ) : orderData.length === 0 ? (
            <TableRow>
              <TableCell colSpan={7} align="center">
                <Typography style={{
                  fontFamily: "Gotham",
                  fontSize: 16,
                  color: "#667085",
                  padding: "20px 0",
                  height:"170px",
                  textAlign:"center"
                }}>
                  No records found
                </Typography>
              </TableCell>
            </TableRow>
          ) : (
            orderData.map((order, index) => (
              <TableRow data-test-id="tableRow" key={index} onClick={() => this.handleRowClick(index,order.id)}>
                <TableCell className="orderNumber-values">{order.id}</TableCell>
                <TableCell className="itemsQty-values">{order.attributes.quantity}</TableCell>
                <TableCell className="totalPrice-values" >{order.attributes.total_price}</TableCell>
                <TableCell className="customer-name" >Betabox</TableCell>
                <TableCell className="shipByDate" >{formatDateToMonth(order.attributes.shipped_from)}</TableCell>
                <TableCell className="status">
                  <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start"}}>
                    <div style={{marginBottom:'8px'}} className="statusChip">
                      {getStatusChip(order.attributes.status)}
                    </div>
                    <div className="paymentChip">
                      {getPaymentChip(order.attributes.payment_status)}
                    </div>                   
                  </div>
                </TableCell>
                <TableCell className="status">
                  <div className="status-btns">
                  {order.attributes.payment_status === "paid" ? (
                    <IconButton className="status-btn">
                      <Image source={deliveredPaidButton} style={{ width: 50, height: 50 }}/>
                    </IconButton>
                  ) : order.attributes.payment_status === "paid_via_warp_pay" ? (
                    <IconButton className="status-btn">
                      <Image source={deliveredWarpPayButton} style={{ width: 50, height: 50 }} />
                    </IconButton>
                  ) : order.attributes.payment_status === "notpaid" ? (
                    <IconButton className="status-btn">
                      <Image source={notShippedNotPaidButton} style={{ width: 50, height: 50 }}/>
                    </IconButton>
                  ):order.attributes.payment_status === "at_warp_speed" ? (
                    <IconButton className="status-btn">
                      <Image source={deliveredAtWarpSpeedButton} style={{ width: 50, height: 50 }} />
                    </IconButton>
                  ) :order.attributes.payment_status === "delayed" ? (
                    <IconButton className="status-btn">
                      <Image source={shippedNotPaidButton} style={{ width: 50, height: 50 }}/>
                    </IconButton>
                  ):
                  <IconButton className="status-btn">
                  <Image source={shippedNotPaidButton} style={{ width: 50, height: 50 }}/>
                </IconButton>}
                  </div>
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
        </Table>
      </TableContainer>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    // Merge Engine - render - Start
    return (
      <ScrollView
        keyboardShouldPersistTaps="always"
        style={styles.container}
        testID="sales-reporting-title"
      >
        <View style={styles.imageContainer}>
          <Image source={titleIcon} style={styles.titleIcon}/>
        </View>
        <View  >
          {this.renderAnalytics()}
          {this.renderOrdersTable()}
        </View>
        <Drawer anchor="right" open={this.state.isDrawerOpen}  >
        <div
          style={{ width: 800, padding: 0 }}
          role="presentation"
        >
    <div className="vendor-shipment-container">
      <div className="orderHeader">
      <div className="order-header-text">Order #{this.state.orderNumber}</div>
      <button data-test-id="closeButton" className="custom-close-button" onClick={this.handleDrawerClose}>
      <img src={CloseIcon}/>
      </button>
      </div>
      <div className="shipment-actions">
        <div className={this.state.isPcakageing?"action-item4":"action-item1"} onClick={this.handleShipmentDetails}>
          <div className={this.state.isPcakageing?"actionImg1":""}>
          <img className="packingListIconImg" src={PackingListIcon} alt="Packing List Icon" />
          </div>
          <span className={this.state.isPcakageing?"shipmentDetailsIconText":"packingListText"} >Packing List</span>
        </div>
        <div data-test-id="packagingButton" className={this.state.isPcakageing?"action-item3":"action-item2"} onClick={this.handleShipmentDetails}>
        <div className={!this.state.isPcakageing?"actionImg1":""}>
          <img className="shipmentDetailsIconImg" src={ShipmentDetailsIcon} alt="Shipment Details Icon" />
          </div>
          <span className={this.state.isPcakageing?"packingListText":"shipmentDetailsIconText"} >Shipment Details</span>
        </div>
      </div>
      {this.state.isPcakageing ?
      <Table className="order-table">
      <TableHead className="tableHeading">
        <TableRow className="table-row-heading">
          <TableCell className="headings">Part Name</TableCell>
          <TableCell className="headings">Quantity</TableCell>
          <TableCell className="headings">Price Per Unit</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {this.state.orderData[this.state.index]?.attributes.packing_list.data.map((order) => (
          <TableRow key={order.id}>
            <TableCell className="orderName">{order.attributes.name}</TableCell>
            <TableCell className="orderQty">{order.attributes.quantity}</TableCell>
            <TableCell className="orderQty">${order.attributes.credits_cost}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table> :
       <Formik
        initialValues={this.state.orderData[this.state.index].attributes}
        validationSchema={validationSchema}
        onSubmit={this.handleSubmit}
      >
        {({ values, handleChange }) => (
          <Form className="shipment-form">
            <div className="vendor-shipment-section">
              <label className="headingShipment">Customer Name</label>
              <Field
                type="text"
                name="customerName"
                value="Betabox, Inc."
                onChange={handleChange}
                className="shipmentInput"
                readOnly
              />
            </div>

            <div className="vendor-shipment-section">
              <label className="headingShipment">Shipment Address</label>
              <Field
                type="text"
                name="shipmentAddress"
                value="715 Barbour Dr. Raleigh, NC 27603"
                onChange={handleChange}
                className="shipmentInput"
                readOnly
              />
            </div>

            <div className="vendor-shipment-section">
              <label className="headingShipment">Type</label>
              <Field
                type="text"
                name="type"
                value={values.order_status}
                onChange={handleChange}
                className="shipmentInput"
                readOnly
              />
            </div>

            <div className="vendor-shipment-section">
              <label className="headingShipment">Ship By Date</label>
              <Field
                type="date"
                name="shipByDate"
                defaultValue={values.ship_by_date ? values.ship_by_date : ''}
                value={values.ship_by_date || ''}
                onChange={handleChange}
                className="shipmentInput"
                readOnly
                placeholder="Ship Date"
              />
            </div>

            <div className="vendor-shipment-section">
              <label className="headingShipment">Order Status</label>
              <Field
                as="select"
                name="status"
                value={values.status}
                onChange={handleChange}
                className="shipmentInput"
              >
                <option value="shipped">Shipped</option>
                <option value="delayed">Pending</option>
                <option value="delivered">Delivered</option>
                <option value="not_shipped">Not Shipped</option>
              </Field>
            </div>

            <Grid container spacing={2}>
              <Grid item sm={6}>
                <div className="vendor-shipment-section">
                  <label className="headingShipment">Earliest Expected Delivery Date</label>
                  <Field
                    type="date"
                    name="earliest_expected_delivery_date"
                    value={
                      values.earliest_expected_delivery_date
                        ? new Date(values.earliest_expected_delivery_date).toISOString().split('T')[0]
                        : ''
                    }
                    onChange={handleChange}
                    className="shipmentInput"
                  />
                </div>
              </Grid>

              <Grid item sm={6}>
                <div className="vendor-shipment-section">
                  <label className="headingShipment">Latest Expected Delivery Date</label>
                  <Field
                    type="date"
                    name="latest_expected_delivery_date"
                    value={
                      values.latest_expected_delivery_date
                        ? new Date(values.latest_expected_delivery_date).toISOString().split('T')[0]
                        : ''
                    }
                    onChange={handleChange}
                    className="shipmentInput"
                  />
                </div>
              </Grid>
            </Grid>

            <div className="vendor-shipment-section">
              <label className="headingShipment">Delivery Tracking Link</label>
              <Field
                data-test-id="trackingurl"
                type="text"
                name="tracking_url"
                value={values.tracking_url}
                onChange={handleChange}
                className="shipmentInput"
              />
              <ErrorMessage name="trackingLink" component="div" className="error" />
            </div>

            <div className="vendor-shipment-actions-btn">
              <button type="button">Cancel</button>
              <button type="submit">Update Order</button>
            </div>
          </Form>
        )}
     </Formik> }
    </div>
    </div>
    </Drawer>
      </ScrollView>
    );
    // Merge Engine - render - End
    // Customizable Area End
  }
}


const styles = StyleSheet.create({
  imageContainer: {
    marginVertical: 15,
    alignItems: "flex-start"
  },
  titleIcon: {
    width: 150,
    height: 50,
    resizeMode: 'contain',
  },
  container: {
    flex: 1,
    padding: 16,
    backgroundColor: "#fff",
  },
  analyticsContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 24,
  },
  analyticsBox: {
    width: "32.5%",
    padding: 16,
    backgroundColor: "#FFFFFF",
    borderRadius: 10,
    alignItems: "flex-start",
    borderWidth: 2,
    borderColor: "#F2F4F7",
  },
  analyticsTitle: {
    fontSize: 24,
    fontWeight: "300",
    fontFamily: "Gotham, sans-serif",
    lineHeight: 32,
    color: "#344054"

  },
  analyticsValue: {
    fontFamily: "Gotham-Light, !important",
    fontWeight: "700",
    fontSize: 48,
    marginVertical: 15,
    lineHeight: 60,
    color: "#101828"
  },
  analyticsLink: {
    fontFamily: "Gotham-Light, sans-serif",
    fontWeight: "400",
    fontSize: 14,
    color: "#007DC6",
    textDecorationLine: "none",
  },

  statusContainer: {
    flexDirection: "row",
  },
  statusBadge: {
    backgroundColor: "#d3d3d3",
    borderRadius: 5,
    padding: 5,
    marginHorizontal: 2,
    fontSize: 12,
    textAlign: 'center',
  },
});

