export const formatDate = (dateString: string | null): string => {
    if (!dateString) {
      return "No date";
    }
  
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1; 
    const year = date.getFullYear().toString().slice(-2);
  
    return `${month}/${day}/${year}`;
  };